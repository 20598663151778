import * as constants from "../../../../common/constants";
const country = "The Netherlands"

export default [
    {
        name: "The Traveling Seven",
        city: "Zutphen",
        country,
        from: new Date(2020, 7),
        to: new Date(2020, 9),
        href: 'https://thetravelingseven.com',
        repo: 'https://gitlab.com/datails/trailingseven-com',
        stack: [
            constants.HEROKU,
            constants.STRAPI,
            constants.GITLAB,
            constants.REACT,
            constants.REACT_MATERIAL_DESIGN,
            constants.HTML,
            constants.CSS,
            constants.JS,
            constants.GCP,
            constants.MONGO
        ]
    },
    {
        name: "Stichting (V)écht ervoor!",
        city: "Zuthpen",
        country,
        from: new Date(2020, 9),
        to: new Date(2020, 11),
        href: 'https://vechtervoor.nl/',
        repo: 'https://gitlab.com/datails/vecht-ervoor',
        stack: [
            constants.GCP,
            constants.REACT,
            constants.REACT_MATERIAL_DESIGN,
            constants.GCP,
            constants.NEST_JS,
            constants.NODE_JS,
            constants.GITLAB,
            constants.EXPRESS,
        ]
    },
    {
        name: "Natuurcentrum Arnhem",
        city: "Arnhem",
        country,
        from: new Date(2020, 9),
        to: new Date(2021, 0),
        href: 'https://natuurcentrum-presikhaaf.web.app/',
        repo: 'https://gitlab.com/natuurcentrum/natuurcentrum',
        stack: [
            constants.REACT,
            constants.REACT_ADMIN,
            constants.MONGO,
            constants.EXPRESS,
            constants.HEROKU,
            constants.GCP,
            constants.GITLAB,
            constants.NODE_JS,
        ]
    },
    {
        name: "Church Arnhem",
        city: "Arnhem",
        country,
        from: new Date(2019, 5),
        to: new Date(2019, 6),
        href: 'https://begin-opnieuw.nl/',
        repo: 'https://gitlab.com/leven-met-jezus/website',
        stack: [
            constants.REACT,
            constants.GCP,
            constants.NEST_JS,
            constants.GITLAB,
            constants.REACT_MATERIAL_DESIGN,
        ]
    },
    {
        name: "Battle4kids",
        city: "Zutphen",
        country,
        from: new Date(2021, 1),
        to: new Date(2021, 5),
        stack: []
    },
    {
        name: "DPM",
        city: "Doetinchem",
        country,
        from: new Date(2021, 2),
        to: new Date(2021, 11),
        stack: []
    },
    {
        name: "Voedselbank Arnhem",
        city: "Arnhem",
        country,
        from: new Date(2017, 9),
        to: new Date(2018, 2),
        stack: [
            constants.JS,
            constants.CSS,
            constants.WEBPACK,
            constants.HTML,
            constants.WORDPRESS
        ]
    },
]