function calculateYearsPassed(sinceDate) {
  const startDate = new Date(sinceDate);
  const currentDate = new Date();
  const timeDifference = currentDate - startDate;
  const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;
  const yearsPassed = timeDifference / millisecondsPerYear;
  return Math.floor(yearsPassed);
}

export default {
    title: "Career",
    desc: `Hi, I'm Robert-Jan a Senior Software Engineer, with over ${calculateYearsPassed("2016-01-01")} years of experience. I worked for start-ups, scale-ups, SME's and corporates using various stacks. I also did some side business via [Upwork](https://www.upwork.com/ab/account-security/login?redir=/freelancers/~016d82fb6dca14ca80).`
}