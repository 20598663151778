import React from "react";
import { Grid, Typography } from '@material-ui/core';
import AppCard from '../app-card';
import useStyles from './catalogue.styles';
import withRoot from '../../theme/withRoot';

function Projects({ projects, title }) {
  const classes = useStyles()

  return (
      <Grid 
        container
        direction="row"
        className={classes.container}
        spacing={10}
      >
        <Grid item xs={12} sm={12} md={12} className={classes.content}>
          <Typography
            component="h2"
            variant="h1"
            align="left"
            color="textPrimary"
            gutterBottom
            className={classes.title}
          >
            {title}
          </Typography>
        </Grid>
        {
          projects.map(project => (
            <Grid item xs={12} sm={12} md={6} lg={4} className={classes.content}>
              <AppCard 
                title={project.title} 
                image={project.image}
                repo={project.repo}
                href={project.href}
              >
                {project.desc}
              </AppCard>
            </Grid>
          ))
        }
      </Grid>
  );
}

export default withRoot(Projects)