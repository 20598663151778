import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Avatar,
} from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import classNames from "classnames";
import { AppButton } from "../../components";
import useStyles from "./banner.styles"
import withRoot from "../../theme/withRoot";
import content from "./banner.text";

function AppBanner({ backgroundColor }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
      style={{ backgroundColor: backgroundColor }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={10}
        xl={8}
        justify="center"
        alignItems="center"
      >
        <Paper className={classes.paperContainer}>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            justify="center"
            alignItems="center"
            className={classes.flex}
          >
            <Avatar
              className={classes.avatar}
              alt={content.title}
            >
              <EventAvailableIcon className={classes.icon} />
            </Avatar>
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={6}
            justify="center"
            alignItems="start"
            className={classes.flex}
            direction="column"
          >
            <Typography
              component="h2"
              variant="h6"
              className={classNames(classes.contentText, classes.fontWeight700)}
            >
              {content.title}
            </Typography>
            <Typography
              component="h3"
              variant="subtitle1"
              className={classes.contentText}
            >
              {content.desc}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            justify="center"
            alignItems="center"
            className={classes.flex}
          >
            <AppButton variant="text" href="/contact">Contact</AppButton>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withRoot(AppBanner)