import { createMuiTheme } from "@material-ui/core/styles";
import {
  indigo,
  green,
  grey,
  red,
  cyan,
  orange,
} from "@material-ui/core/colors";

const rawTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#f2713a",
    },
    secondary: {
      main: "#ffca91",
    },
    warning: {
      main: "#f2713a",
    },
    error: red,
    success: green,
  },
  typography: {
    fontFamily: "'Raleway', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700,
    fontFamilyPrimary: "'Raleway', sans-serif",
    fontFamilySecondary: "'Raleway', sans-serif",
  },
  overrides: {
    MuiButton: {
      text: {
        maxWidth: '250px',
        margin: '20px 0 0', 
        background: 'linear-gradient(to top right,#4f7afb,#39d264)',
        backgroundImage: 'linear-gradient(to top right,#5f40c3,#26dcce)', 
        color: '#FFF',
        fontSize: "1.1rem",
        height: "60px",
        minWidth: "175px",
        backgroundColor: "#FFF",
        "&:hover": {
          boxShadow: '0px 0px 7px 7px rgb(0 0 0 / 8%), 0px 0px 7px 8px rgb(0 0 0 / 3%), -9px 11px 5px 0px rgb(0 0 0 / 3%)'
        },
      }
    }
  }
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: 400,
  fontFamily: "'Raleway', sans-serif !important",
  textTransform: "uppercase",
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "2.5rem!important",
      },
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "2.1rem!important",
      },
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "2rem!important",
      },
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 36,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.9rem!important",
      },
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.5rem!important",
      },
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.1rem!important",
      },
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1.1rem!important",
      },
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: "1rem!important",
      },
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
      [rawTheme.breakpoints.down("sm")]: {
        fontSize: ".9rem!important",
      },
    },
  },
  container: {
    margin: "0",
    width: "100%",
    padding: "40px",
    [rawTheme.breakpoints.down("sm")]: {
      padding: "40px 0",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "40px",
  },
  overrides: {
    ...rawTheme.overrides,
    MuiButton: {
      ...rawTheme.overrides.MuiButton,
      fullWidth: {
        ...rawTheme.overrides.MuiButton.text,
        width: "100%",
        minWidth: '100%',
        maxWidth: '100%'
      },
    }
  }
};

export default theme;
