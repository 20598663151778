import React from "react";
import AppTextImage from "../../../../components/app-text-image";
import AppButton from "../../../../components/app-button";

function calculateYearsPassed(sinceDate) {
  const startDate = new Date(sinceDate);
  const currentDate = new Date();
  const timeDifference = currentDate - startDate;
  const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;
  const yearsPassed = timeDifference / millisecondsPerYear;
  return Math.floor(yearsPassed);
}

export default function TextArea() {
  return (
    <AppTextImage
      backgroundColor="#FFF"
      title="About me"
      reverse
      text={`${calculateYearsPassed("2016-01-01")}+ years of experience as a Senior Backend and DevOps Engineer with expertise in NodeJS, Python, and Go. Proficient in AWS and GCP, API development (GraphQL, REST), CLI development, automated testing, ETL processes database migrations, CI/CD (GitLab, Jenkins, GitHub Actions), and databases (DynamoDB, MongoDB, Redis, Postgres, MySQL). Expert in Terraform and cloud engineering. Married since 2015 to my lovely wife and father of 5 boys.`}
      image={require("../../../../assets/selfie.jpg")}
    >
      <AppButton href="/career" variant="text">
        Learn more
      </AppButton> 
    </AppTextImage>
  );
}
