import {
    makeStyles,
    createStyles,
  } from "@material-ui/core";

export default makeStyles((theme) =>
createStyles({
  colorDark: {
    color: "#000!important",
  },
  heartBeatIcon: {
    color: "#FFF",
    fontSize: "2rem",
  },
  heartBeatIconAwake: {
    color: "#000",
  },
  menuButton: {
    color: "#FFF",
    marginRight: "30px",
  },
  navigationBar: {
    position: "fixed",
    top: "-74px",
    transition: "transform 0.2s",
    transform: "translateY(74px)",
  },
  navigationBarHide: {
    transform: "translateY(0)",
  },
  navigationBarInitial: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  navigationBarInner: {
    justifyContent: "space-between",
  },
  offset: {
    height: "74px",
  },
  offset0: {
    height: 0,
  },
  root: {
    flexGrow: 1,
    maxHeight: "64px",
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Megrim !important',
    color: '#FFF',
    fontWeight: 700
  },
  titleAwake: {
    color: "#000",
  },
  logo: {
    display: "flex",
    justifySelf: "center",
    alignSelf: "center",
    backgroundSize: "cover",
    width: 40
  },
  logoRevert: {
    display: "flex",
    justifySelf: "center",
    alignSelf: "center",
    backgroundSize: "cover",
  },
})
);