import { makeStyles, createStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    container: {
      margin: "0",
      width: "100%",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      padding: '80px 320px',
      [theme.breakpoints.down("lg")]: {
        padding: "40px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "40px 0",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "0 40px",
      [theme.breakpoints.down("md")]: {
        padding: "40px !important",
      },
    },
    button: {
      ...theme.overrides.MuiButton,
      minWidth: 175
    },
    title: {
      display: "flex",
      textTransform: "uppercase",
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: "2rem",
      position: "relative",
      marginBottom: "2rem",
      lineHeight: 2,
      color: '#000',
      "&::after": {
        position: "absolute",
        content: '""',
        bottom: 0,
        width: "80px",
        height: "3px",
        background: "#000",
        transform: "translateX(0%)",
        [theme.breakpoints.down("sm")]: {
          display: 'none'
        },
      },
    },
  })
);