import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import { Header, Text } from "./components";
import { AppBreadCrumb } from "../../framework";
import withRoot from "../../theme/withRoot";

function App404() {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          404 not found
        </title>
        <meta
          name="description"
          content="Sorry, we couldn't find this page!"
        />
        <meta
          property="og:description"
          content="Sorry, we couldn't find this page!"
        />
        <meta
          property="og:title"
          content="404 not found"
        />
        <meta property="og:url" content={"https://datails.nl/404"} />
      </Helmet>
      <Divider />
      <Header />
      <Divider />
      <AppBreadCrumb backgroundColor="#e3dff5" current="404-not-found" />
      <Divider />
      <Text />
      <Divider />
    </React.Fragment>
  );
}

export default withRoot(App404)