import React from "react";
import { useRoutes, useInterceptor } from "hookrouter";
import { App404, AppAbout, AppContact, AppHome, AppVolunteering, AppSideProjects } from "../pages";

const routes = {
  "/": () => <AppHome />,
  "/contact": () => <AppContact />,
  "/career": () => <AppAbout />,
  "/volunteering": () => <AppVolunteering />,
  "/side-projects": () => <AppSideProjects />,
};

const App = () => {
  useInterceptor((_currentPath, nextPath) => {
    window.scrollTo(0, 0);
    window.dataLayer.push({
      event: "pageview",
    });
    return nextPath;
  });

  const routeResult = useRoutes(routes);
  return routeResult || <App404 />;
};

export default App;
