import React from "react";
import { AppHeroHeader } from "../../../../components";
import withRoot from "../../../../theme/withRoot";

function Header() {
  return (
    <AppHeroHeader
      subTitle="not found"
      title="404"
    />
  );
}

export default withRoot(Header);