import React from "react";
import { AppTextImage } from "../../../../components";
import withRoot from "../../../../theme/withRoot";

function Text() {
  return (
    <AppTextImage
      backgroundColor="#FFF"
      title="Pagina niet gevonden!"
      text={["Sorry deze pagina hebben wij niet kunnen vinden."]}
    />
  );
}

export default withRoot(Text)