import React from "react";
import { AppTextImage } from "../../../../components";
import { AppTimeline } from "../../../../framework";
import withRoot from "../../../../theme/withRoot";
import volunteerExp from "./side-projects.data";

function Text() {
  return (
    <AppTextImage
      backgroundColor="#FFF"
      title="Other projects"
    >
      <AppTimeline data={volunteerExp} />
    </AppTextImage>
  );
}

export default withRoot(Text)