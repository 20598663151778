import React from "react";
import { AppTextImage } from "../../../../components";
import { AppTimeline } from "../../../../framework";
import withRoot from "../../../../theme/withRoot";
import content from "./content.text";
import career from "./timeline.data";

function Content() {
  return (
    <>
        <AppTextImage
      backgroundColor="#FFF"
      title={content.title}
      text={content.desc}
      image={require("../../../../assets/selfie.jpeg")}
      reverse
    >
    </AppTextImage>
      <AppTextImage
        backgroundColor="#FFF"
      >
        <AppTimeline data={career}/>
      </AppTextImage>
      </>
  );
}

export default withRoot(Content)