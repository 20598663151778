import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from './app-hero-header.styles';
import withRoot from '../../theme/withRoot'

function AppHeroHeader({ subTitle, title }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        spacing={10}
        className={classes.container}
        justify="space-around"
      >
        <Grid item xs={12} className={classes.content}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            className={classes.title}
            gutterBottom
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              className={classes.subTitle}
              style={{
                color: "rgba(255, 255, 255, 0.5)",
              }}
              paragraph
            >
              {subTitle}
            </Typography>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withRoot(AppHeroHeader)