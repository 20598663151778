import React, { useContext } from "react";
import {
  List,
  Divider,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from "@material-ui/core";
import { navigate, } from "hookrouter";
import { Context } from "../../store/store";
import withRoot from "../../theme/withRoot"
import useStyles from './drawer.styles';

function AppDrawer() {
  const [state, dispatch] = useContext(Context);
  const classes = useStyles();

  const updateDrawer = () => {
    dispatch({
      type: "TOGGLE_DRAWER",
    });
  };

  const sideList = (
    <div className={classes.list}>
      <Divider />
      <List>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => navigate("/")}
        >
          <ListItemText primary={"Home"} className={classes.drawerText} />
        </ListItem>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => navigate("/career")}
        >
          <ListItemText primary={"Career"} className={classes.drawerText} />
        </ListItem>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => navigate("/volunteering")}
        >
          <ListItemText primary={"Volunteering"} className={classes.drawerText} />
        </ListItem>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => navigate("/side-projects")}
        >
          <ListItemText primary={"Opensource"} className={classes.drawerText} />
        </ListItem>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => window.location = "https://gitlab.com/datails"}
        >
          <ListItemText primary={"GitLab"} className={classes.drawerText} />
        </ListItem>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => window.location = "https://github.com/rjpkuyper"}
        >
          <ListItemText primary={"GitHub"} className={classes.drawerText} />
        </ListItem>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => window.location = "https://medium.com/@datails"}
        >
          <ListItemText primary={"Blog"} className={classes.drawerText} />
        </ListItem>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => window.location = "https://www.upwork.com/freelancers/~016d82fb6dca14ca80"}
        >
          <ListItemText primary={"Hire me (remote)"} className={classes.drawerText} />
        </ListItem>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => window.location = "https://stackoverflow.com/users/11067065/robert-jan-kuyper"}
        >
          <ListItemText primary={"Stackoverflow"} className={classes.drawerText} />
        </ListItem>
        <ListItem
          button
          className={classes.drawerLink}
          onClick={() => navigate("/contact")}
        >
          <ListItemText primary={"Contact"} className={classes.drawerText} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <SwipeableDrawer
        open={state.drawer}
        onClose={updateDrawer}
        onOpen={updateDrawer}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={updateDrawer}
          onKeyDown={updateDrawer}
        >
          {sideList}
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default withRoot(AppDrawer)