import React from "react";
import { AppTextImage } from "../../../../components";
import { AppForm } from "../../../../framework";
import withRoot from "../../../../theme/withRoot";
import content from "./content.text";

function Content() {
  return (
    <AppTextImage
      title={content.title}
      text={content.desc}
      backgroundColor="#FFF"
    >
      <AppForm />
    </AppTextImage>
  );
}

export default withRoot(Content)