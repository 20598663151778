import React from "react";
import AppHeader from "../../../../components/app-hero-header";

export default function TextArea() {
  return (
    <AppHeader
      subTitle="Bekijk de mogelijkheden"
      title="Contact"
    />
  );
}
