import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
    },
    textfield: {
      width: "100%",
      color: '#FFF !important',
      borderColor: "#FFF"
    },
    paper: {
      width: "100%",
      maxWidth: "450px",
      minHeight: "500px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: theme.typography.fontFamily,
      padding: "0 40px",
      margin: 0,
      color: '#FFF'
    },
    form: {
      display: "flex",
      width: "100%",
      minHeight: "450px",
      alignItems: "flex-start",
      justifyContent: "space-between",
      fontFamily: theme.typography.fontFamily,
      color: '#FFF !important'
    },
    formHidden: {
      display: "none",
    },
    container: theme.container,
    content: theme.content,
    subTitle: {
      fontWeight: 500,
    },
  }));