import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    card: {
      width: "100%",
      boxShadow: "0 0 2.5rem 0.3125rem rgba(0,0,0,.1)",
      borderRadius: '2.5rem'
    },
    media: {
      width: '75%',
      margin: '40px auto',
    },
    fontStyles: {
      fontFamily: theme.typography.fontFamily,
      margin: '0 0 25px',
      fontWeight: 500,
    },
    text: {
      fontSize: '1.1rem !important'
    },
    cardContent: {
      padding: '40px'
    },
    cardActions: {
      padding: '0 40px 40px',
      flexDirection: 'column',
    }
  }));
  