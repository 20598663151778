const CLOUD_FUNCTION_URI = "https://us-central1-datails.cloudfunctions.net/api/send";

const getFormDataFromEvent = (event) => {
    return [...new FormData(event.target).entries()].reduce((acc, [ key, value ]) => {
        return {
            ...acc,
            [key]: value
        }
    }, {})
}

const isResponseSuccessFull = (resp) => {
    return resp?.status === 204 || resp?.status === 202 || resp?.status === 200 || resp?.ok
}

export async function sendFormData({ event, recaptchaRef }){
    event.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();
    const formData = getFormDataFromEvent(event);

    const resp = await fetch(CLOUD_FUNCTION_URI, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            ...formData,
            recaptchaValue,
        }),
    }).catch(err => {
        console.error(err)
    })

    if (isResponseSuccessFull(resp)) {
      return true
    }

    return false
}