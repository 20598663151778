import React from "react";
import { Divider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import AppTextTop from "./components/text-top";
import AppHeader from "./components/header";
import { AppBanner } from "../../framework";
import withRoot from "../../theme/withRoot";

function AppHome() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Senior Backend and DevOps Engineer - NodeJS, Python, Go, Terraform</title>
        <meta
          name="description"
          content="Hi, I am Robert-Jan, a Senior Backend Engineer from Arnhem, available for remote freelance projects. I have extensive experience in NodeJS, Python, and Go. Additionally, I am a DevOps engineer with a strong background in Terraform and cloud engineering."
        />
        <meta
          property="og:description"
          content="Hi, I am Robert-Jan, a Senior Backend Engineer from Arnhem, available for remote freelance projects. I have extensive experience in NodeJS, Python, and Go. Additionally, I am a DevOps engineer with a strong background in Terraform and cloud engineering."
        />
        <meta
          property="og:title"
          content="Senior Backend and DevOps Engineer - NodeJS, Python, Go, Terraform"
        />
        <meta property="og:url" content={"https://datails.nl"} />
      </Helmet>
      <AppHeader />
      <Divider />
      <AppTextTop />
      <Divider />
      <Divider />
      <AppBanner />
      <Divider />
    </React.Fragment>
  );
}

export default withRoot(AppHome);
