import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core";
import { AppBreadCrumb, AppBanner } from "../../framework";
import withRoot from "../../theme/withRoot";
import { Header, Content } from "./components";

function AppAbout() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Career of Robert-Jan</title>
        <meta
          name="description"
          content="Hi, I am Robert-Jan, a Senior Backend Engineer from Arnhem, available for remote freelance projects. I have extensive experience in NodeJS, Python, and Go. Additionally, I am a DevOps engineer with a strong background in Terraform and cloud engineering."
        />
        <meta
          property="og:description"
          content="Hi, I am Robert-Jan, a Senior Backend Engineer from Arnhem, available for remote freelance projects. I have extensive experience in NodeJS, Python, and Go. Additionally, I am a DevOps engineer with a strong background in Terraform and cloud engineering."
        />
        <meta
          property="og:title"
          content="About Robert-Jan"
        />
        <meta property="og:url" content={"https://datails.nl/about"} />
      </Helmet>
      <Divider />
      <Header />
      <Divider />
      <AppBreadCrumb backgroundColor="#e3dff5" />
      <Divider />
      <Content />
      <Divider />
      <AppBanner backgroundColor="#fff" />
      <Divider />
    </React.Fragment>
  );
}

export default withRoot(AppAbout)