import React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import CodeIcon from "@material-ui/icons/Code";
import classNames from "classnames";
import useStyles from "./footer.styles";
import content from "./footer.content";
import withRoot from "../../theme/withRoot";

function AppFooter() {
  const classes = useStyles();

  return (
    <>
    <Grid container className={classes.container} justify="space-around">
      <Grid item xs={12} sm={12} md={4} lg={4} className={classes.content}>
        <Typography variant="h2" gutterBottom className={classes.title}>
          {content.title}
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.text}>
        {content.desc}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} className={classes.content}>
        <Typography variant="h2" gutterBottom className={classes.title}>
          Contact
        </Typography>
        <Typography variant="body1" className={classes.anchor}>
          <Link href="tel:06 11770007" target="_blank" color="inherit">
            06 11770007
          </Link>
        </Typography>
        <Typography variant="body1" className={classes.anchor}>
          <Link href="mailto:info@datails.nl" target="_blank" color="inherit">
            info@datails.nl
          </Link>
        </Typography>
        <Typography variant="body1" className={classes.anchor}>
          <Link href="https://gitlab.com/datails" target="_blank" color="inherit">
            GitLab
          </Link>
        </Typography>
        <Typography variant="body1" className={classes.anchor}>
          <Link href="https://github.com/rjpkuyper" target="_blank" color="inherit">
            GitHub
          </Link>
        </Typography>
        <Typography variant="body1" className={classes.anchor}>
          <Link href="https://stackoverflow.com/users/11067065/robert-jan-kuyper" target="_blank" color="inherit">
            Stackoverflow
          </Link>
        </Typography>
        <Typography variant="body1" className={classes.anchor}>
          <Link href="/contact" color="inherit">
            Send a message
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} className={classes.content}>
        <Typography variant="h2" gutterBottom className={classes.title}>
            Info
        </Typography>
        {
            content.misc.map(misc => (
            <Typography variant="body1" gutterBottom className={classes.text}>
                {misc}
            </Typography>
          )
          )
        }
      </Grid>
    </Grid>
    <Grid
      container
      className={classNames([classes.container, classes.backgroundMain])}
      justify="space-around"
    >
      <Grid item xs={12} className={classes.content}>
        <Typography variant="body1" className={classes.anchor}>
          <Link
            href="https://gitlab.com/datajs/web-site"
            target="_blank"
            color="inherit"
            className={classes.colorLight}
          >
            Show me the <CodeIcon />!
          </Link>
        </Typography>
      </Grid>
    </Grid>
    </>
  );
}

export default withRoot(AppFooter)