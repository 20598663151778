import React from 'react';
import { Paper, Typography, Link } from '@material-ui/core';
import { 
    Timeline, 
    TimelineItem, 
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent,
    TimelineDot
} from "@material-ui/lab";
import { CodeOutlined } from "@material-ui/icons"
import useStyles from "./timeline.styles";
import withRoot from '../../theme/withRoot';

function sortOnDate(first, second) {
    return new Date(second.from) - new Date(first.from);
}

function AppTimeLine({ data }) {
  const classes = useStyles();

  return (
    <Timeline align="alternate" className={classes.marginTop}>
        {data.sort(sortOnDate).map(role => (
            <TimelineItem>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                {role.from.toLocaleDateString()} - {role.to.toLocaleDateString()}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <CodeOutlined />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h5" component="h2">
                  {role.title}
                </Typography>
                <Typography variant="b" component="b">{role.name}, {role.city} ({role.country})</Typography>
                <Typography>{role.stack.join(', ')}</Typography>
                {role.repo && (
                    <Link href={role.repo} target="_blank">
                        Repository
                    </Link>
                )}
                {role.href && (
                    <Link href={role.href} className={classes.marginLeft} target="_blank">
                        Showcase
                    </Link>
                )}
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}

export default withRoot(AppTimeLine)