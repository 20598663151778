import React from "react";
import AppHeader from "../../../../components/app-hero-header";
import withRoot from "../../../../theme/withRoot";

function Header() {
  return (
    <AppHeader
      title="Opensource projects"
    />
  );
}

export default withRoot(Header)