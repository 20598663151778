import React from "react";
import { Grid, Typography } from "@material-ui/core";
import classnames from 'classnames'
import useStyles from './header.styles';
import content from './header.content';

export default function Header() {
  const classes = useStyles();

  return (
    <Grid container spacing={10} className={classnames(classes.minHeight, classes.root)} justify="center">
        <Grid item md={6} lg={5} className={classes.content}>
          <div className={classnames(classes.flex, classes.hiddenSmallMd)}>
          {<img src={content.image} alt="DATAILS Logo" className={classes.logo} />}
            <Typography
              component="h5"
              variant="h1"
              align="left"
              color="textPrimary"
              className={classnames(classes.colorWhite, classes.marginNone)}
              gutterBottom
            >
              <span className={classes.fontStyles}>{content.subtitle}</span>
              <br />
            </Typography>
          </div>
          <Typography
            component="h1"
            variant="h2"
            align="left"
            color="textPrimary"
            className={classnames(classes.colorWhite, classes.centerMdDown)}
            gutterBottom
          >
            {content.title}
          </Typography>
          <Typography variant="h5" component="h2" align="left" color="textSecondary" className={classnames(classes.colorWhite, classes.hiddenSmallMd)} paragraph>
              {content.desc}
          </Typography>
        </Grid>
      </Grid>
  );
}
