import React from "react";
import Button from "@material-ui/core/Button";
import withRoot from '../../theme/withRoot';

function ButtonComponent({
  children,
  href,
  target,
  variant = "fullWidth"
}) {
  return (
    <Button
      href={href}
      variant={variant}
      target={target}
    >
      {children}
    </Button>
  );
}

export default withRoot(ButtonComponent)
