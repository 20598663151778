import React, { useContext } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  FormControl,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { Context } from "../../store/store";
import AppSnackbar from "../snackbar";
import withRoot from "../../theme/withRoot";
import useStyles from "./form.styles";
import { sendFormData } from './form.utils'

function AppForm({ style }) {
  const recaptchaRef = React.createRef();
  const classes = useStyles();
  const [, setValue] = React.useState("Controlled");
  const [, dispatch] = useContext(Context);
  const [formValue, setFormValue] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar({
      open: false,
      message: "",
      severity: "",
    });
  };

  const updateLoader = () => {
    dispatch({
      type: "TOGGLE_LOADER",
    });
  };

  const formHandler = async (event) => {
    updateLoader();

    const isFormSend = await sendFormData({ event, recaptchaRef });

    if (isFormSend) {
      setFormValue(true);
      setSnackBar({
        open: true,
        message: "Succesvol verzonden!",
        severity: "success",
      });
    } else {
      setSnackBar({
        open: true,
        message: "Er is iets fout gegaan!",
        severity: "error",
      });
    }

    updateLoader();
  };

  return formValue ? (
    <Typography gutterBottom variant="h5" component="h2">
      Successfully send an email!
    </Typography>
  ) : (
    <form
      id="contact-form"
      style={style}
      onSubmit={formHandler}
      className={classes.form}
    >
      <FormControl className={classes.form}>
        <TextField
          id="naam"
          label="Name"
          name="naam"
          multiline
          variant="outlined"
          onChange={handleChange}
          className={classes.textfield}
          required
        />
        <TextField
          id="email"
          label="Email"
          name="email"
          multiline
          variant="outlined"
          onChange={handleChange}
          className={classes.textfield}
          required
        />
        <TextField
          id="message"
          name="message"
          label="Message"
          multiline
          rows={5}
          variant="outlined"
          onChange={handleChange}
          className={classes.textfield}
          required
          color="#FFF"
          
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Lcq5xEaAAAAAKrXDOIcvh05vebBLEEYVyp3-9Lo"
        />
        <Button className={classes.button} type="submit">
          Send
        </Button>
        {snackBar.open ? (
          <AppSnackbar
            open={snackBar.open}
            message={snackBar.message}
            severity={snackBar.severity}
            handler={handleClose}
          />
        ) : (
          ""
        )}
      </FormControl>
    </form>
  );
}

export default withRoot(AppForm)