import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    marginTop: {
        marginTop: 40
    },
    paper: {
      padding: '6px 16px',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
    marginLeft: {
      marginLeft: 15
    }
  }));