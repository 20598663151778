import React from "react";
import { Divider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { AppHeader, AppText } from "./components"
import { AppBanner } from "../../framework";
import withRoot from "../../theme/withRoot";

function AppSideProjects() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Side projects (open source)</title>
        <meta
          name="description"
          content="Side projects (open source)"
        />
        <meta
          property="og:description"
          content="Side projects (open source)"
        />
        <meta
          property="og:title"
          content="Side projects (open source)"
        />
        <meta property="og:url" content={"https://datails.nl/side-projects"} />
      </Helmet>
      <AppHeader />
      <Divider />
      <AppText />
      <Divider />
      <AppBanner />
      <Divider />
    </React.Fragment>
  );
}

export default withRoot(AppSideProjects);
