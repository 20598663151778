import React from "react";
import AppHeader from "../../../../components/app-hero-header";
import withRoot from "../../../../theme/withRoot";

function Header() {
  return (
    <AppHeader
      subTitle="Giving back to the community"
      title="Volunteering"
    />
  );
}

export default withRoot(Header)