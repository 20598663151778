import React from "react";
import { Breadcrumbs as MDBreadCrumbs, Grid, Typography, Link } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import useStyles from './breadcrumb.styles'
import withRoot from '../../theme/withRoot'

function Breadcrumbs({ backgroundColor, current }) {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      justify="space-around"
      style={{ backgroundColor }}
    >
      <Grid item xs={12} className={classes.content}>
        {(({ location }) => {
          const pathnames = location.pathname.split("/").filter((x) => x);

          return (
            <MDBreadCrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link color="inherit" href="/">
                Home
              </Link>
              {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                return last ? (
                  <Typography color="textPrimary" key={to}>
                    {current ? current : to.split("/")?.[1]}
                  </Typography>
                ) : (
                  <Link color="inherit" href={to} key={to}>
                    {to.split("/")?.[1]}
                  </Link>
                );
              })}
            </MDBreadCrumbs>
          );
        })(window)}
      </Grid>
    </Grid>
  );
}

export default withRoot(Breadcrumbs)