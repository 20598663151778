import { createStyles, makeStyles } from "@material-ui/core";
export default makeStyles((theme) =>
  createStyles({
    container: {
      margin: "0",
      width: "100%",
      padding: '80px 250px',
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      background: "#FFF1E3",
      [theme.breakpoints.down("lg")]: {
        padding: "40px 0",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "40px",
    },
    image: {
      width: '100%',
      borderRadius: "2.5rem",
      boxShadow: "0 0 2.5rem 0.3125rem rgba(0,0,0,.3)",
    },
    text: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      lineHeight: 1.8,
      textAlign: "justify",
      fontSize: "1.1rem",
      color: '#000',
    },
    title: {
      display: "flex",
      textTransform: "uppercase",
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      fontSize: "2rem",
      position: "relative",
      marginBottom: "2rem",
      lineHeight: 2,
      color: '#000',
      "&::after": {
        position: "absolute",
        content: '""',
        bottom: 0,
        width: "80px",
        height: "3px",
        background: "#000",
        transform: "translateX(0%)",
        [theme.breakpoints.down("sm")]: {
          display: 'none'
        },
      },
    },
  })
);