import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    container: {
      margin: "0",
      width: "100%",
      padding: "40px 80px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px",
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  })
);