import React from "react";
import { Helmet } from "react-helmet";
import { Divider } from "@material-ui/core"
import { Header, Content } from "./components"
import { AppBanner, AppBreadCrumb } from "../../framework";
import withRoot from "../../theme/withRoot";

function AppContact() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Send me a message!</title>
        <meta
          name="description"
          content="Leave a message to me!"
        />
        <meta
          property="og:description"
          content="Leave a message to me!"
        />
        <meta
          property="og:title"
          content="Send me a message!"
        />
        <meta property="og:url" content={"https://datails.nl/contact"} />
      </Helmet>
      <Divider />
      <Header />
      <Divider />
      <AppBreadCrumb backgroundColor="#e3dff5" />
      <Divider />
      <Content />
      <Divider />
      <AppBanner backgroundColor="#fff" />
      <Divider />
    </React.Fragment>
  );
}

export default withRoot(AppContact)