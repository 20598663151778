import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import useStyles from './app-text-image.styles';
import withRoot from '../../theme/withRoot';

function AppTextImage({
  text,
  title,
  reverse = false,
  image,
  backgroundColor,
  children,
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        justify="space-around"
        direction={reverse ? "row-reverse" : "row"}
        className={classes.container}
        spacing={10}
        style={backgroundColor && { backgroundColor: backgroundColor }}
      >
        <Grid item xs={12} sm={12} md={7} lg={7} className={classes.content}>
          {title ? (
            <Typography variant="h2" gutterBottom className={classes.title}>
              {title}
            </Typography>
          ) : (
            ""
          )}
          <ReactMarkdown source={text} className={classes.text} />
          {children}
        </Grid>
        {image && (
          <Grid item sm={12} md={4} lg={3} className={classes.content}>
            <img src={image} alt={title} className={classes.image} />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default withRoot(AppTextImage)