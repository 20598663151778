export const HTML = "HTML5";
export const CSS = "CSS3/POSTCSS/SASS";
export const JS = "JavaScript (ES5/ES6+/VanillaJS)";
export const TS = "TypeScript";
export const JQUERY = "jQuery";
export const BOOTSTRAP = "Bootstrap";
export const MULTI_THREADING = "Multi-threading";
export const VUE = "VueJS";

export const GOLANG = "Go";
export const EXCELIZE = "Excelize";
export const BADGER = "Badger";
export const DESKTOP = "Desktop app";

export const NODE_JS = "NodeJS";
export const EXPRESS = "Express";
export const FASTIFY = "Fastify";
export const NEST_JS = "NestJS";
export const BULL = "BullJS";

export const JEST = "Jest";
export const CYPRESS = "Cypress";
export const KARMA = "Karma";
export const JASMINE = "Jasmine";
export const ARTILLERY = "Artillery";
export const SUPERTEST = "Supertest";
export const WEBDRIVER_IO = "WebdriverIO";

export const REST = "REST API/Swagger/OpenAPI";
export const GRAPHQL = "GraphQL/Apollo";
export const SOAP = "Soap/WSDL/SoapUI";

export const REDIS = "Redis";
export const MONGO = "MongoDB";
export const DYNAMO = "DynamoDB";
export const MY_SQL = "MySQL";
export const POSTGRES = "Postgres";

export const PRISMA = "Prisma";
export const TYPE_ORM = "TypeORM";

export const AWS = "AWS";
export const GCP = "GCP/Firebase";
export const GITLAB = "GitLab (SCM&CI/CD)";
export const GITHUB = "GitHub";
export const BITBUCKET = "BitBucket";
export const JENKINS = "Jenkins";

export const DOCKER = "Docker/Docker-compose";
export const K8S = "Kubernetes";
export const DEV_OPS = "ArgoCD/Prometheus/Kibana";
export const LINUX = "Linux/Ubuntu/Bash";
export const KIBANA = "Kibana";
export const NGINX = "Nginx"

export const REACT = "ReactJS (hooks/classes)";
export const REACT_ADMIN = "React Admin";
export const REACT_MATERIAL_DESIGN = "React Material Design"

export const DNN = "DNN7/DNN8/DNN9";
export const STRAPI = "Strapi";
export const HEROKU = "Heroku";
export const WORDPRESS = "WordPress";

export const WEBPACK = "Webpack";
export const ROLLUP = "Rollup";
export const GULP = "Gulp";
export const NX = 'nx';

export const YARGS = "Yargs";
export const MICROSERVICES = 'Microservices'
export const TERRAFORM = "Terraform"
export const COMMERCE_TOOLS = "CommerceTools"
export const ALGOLIA = "Algolia"
export const AZURE = "Azure"
export const PYTHON = "Python"

export const ADYEN = "Adyen"
export const MOLLIE = "Mollie"