import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions
} from "@material-ui/core";
import classnames from 'classnames';
import AppButton from '../app-button';
import useStyles from './app-card.styles';
import withRoot from '../../theme/withRoot'

function AppCard({ children, image, title, href, repo }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
        <CardMedia
          component="img"
          alt={title}
          image={image}
          title={title}
          className={classes.media}
        />
        <CardContent className={classes.cardContent}>
          <Typography className={classnames(classes.fontStyles)} gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography className={classnames(classes.fontStyles, classes.text)}  variant="body2" color="textSecondary" component="p">
            {children}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          {
            href && (
              <AppButton size="small" color="primary" href={href} target="_blank">
                Showcase
              </AppButton>
            )
          }
          {
            repo && (
              <AppButton size="small" color="primary" href={repo} target="_blank">
                Repository
              </AppButton>
            )
          }

        </CardActions>
    </Card>
  );
}

export default withRoot(AppCard)