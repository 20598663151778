import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    colorWhite: {
      color: "#fff",
    },
    container: {
      minHeight: "450px",
      margin: "-65px 0 0",
      width: "100%",
      background: 'linear-gradient(to top right,#4f7afb,#39d264)',
      backgroundImage: 'linear-gradient(to top right,#5f40c3,#26dcce)',
    },
    content: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.4)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "40px",
    },
    title: {
      color: "white",
      fontFamily: '"Raleway", sans-serif',
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        fontSize: "2.2rem",
      },
    },
    subTitle: {
      color: "#4d4d4d",
      fontFamily: '"Raleway", sans-serif',
      fontSize: "1rem",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);