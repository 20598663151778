import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import { Context } from "../../store/store";
import useStyles from './navigation.styles';
import withRoot from '../../theme/withRoot';

function AppNavigation() {
  const classes = useStyles();
  const [, dispatch] = useContext(Context);
  const [atTopOfPage, setAtTopOfPage] = useState(true);
  const [sleep, setSleep] = useState(false);

  const handleScroll = () => {
    setAtTopOfPage(window.pageYOffset < 275 ? true : false);
    setSleep(window.pageYOffset < 350 ? true : false);
  };

  // show or hide the drawer
  const toggleDrawer = () => {
    dispatch({
      type: "TOGGLE_DRAWER",
    });
  };

  // component did unmount
  useEffect(() => {
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // component did mount
  useEffect(() => window.addEventListener("scroll", handleScroll), []);

  return (
    <div className={classes.root}>
      <AppBar
        color="default"
        position="relative"
        className={
          atTopOfPage
            ? classes.navigationBarInitial
            : sleep
            ? classNames(classes.navigationBar, classes.navigationBarHide)
            : classes.navigationBar
        }
      >
        <Toolbar className={classes.navigationBarInner}>
          <IconButton
            edge="start"
            className={
              atTopOfPage
                ? classes.menuButton
                : classNames(classes.menuButton, classes.colorDark)
            }
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={ atTopOfPage
                ? classes.title
                : classNames(classes.title, classes.colorDark)}>
              DATAILS.
            </Typography>
            <img
              src={require('../../assets/logo-header.png')}
              title="Datails official logo"
              alt="Datails official logo"
              className={classes.logo}
            />
        </Toolbar>
      </AppBar>
      <div className={atTopOfPage ? classes.offset0 : classes.offset}></div>
    </div>
  );
}

export default withRoot(AppNavigation)
