import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
      display: "flex",
      background: "#E8A279",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
        textAlign: "center",
      },
    },
    details: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: "1 0 auto",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      },
    },
    cover: {
      minWidth: 250,
      [theme.breakpoints.down("sm")]: {
        minHeight: 250,
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: theme.palette.primary.main,
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      textTransform: "uppercase",
      margin: "15px 0",
      fontSize: "1.6rem",
    },
    titleBar: {
      display: "flex",
    },
    colorWhite: {
      color: "#FFF1E3",
    },
  }));