import * as constants from "../../../../common/constants";
const country = "The Netherlands"

export default [
    {
        name: "Vakantie Vorden",
        city: "Vorden",
        country,
        from: new Date(2020, 11),
        to: new Date(),
        href: 'http://vakantie-vorden.nl/',
        repo: 'https://gitlab.com/datails/vakantie-vorden-backend',
        stack: [
            constants.REACT,
            constants.REACT_ADMIN,
            constants.REACT_MATERIAL_DESIGN,
            constants.NEST_JS,
            constants.MONGO,
            constants.NODE_JS,
            constants.TS,
            constants.EXPRESS,
            constants.HEROKU,
            constants.GITLAB,
            constants.REST,
        ]
    },
    {
        name: "Excel Formatter",
        city: "Arnhem",
        country,
        from: new Date(2023, 0),
        to: new Date(2023, 4),
        repo: 'https://gitlab.com/datails/excel-parser',
        href: 'https://excel-formatter.firebaseapp.com/',
        stack: [
            constants.REACT,
            constants.GITLAB,
            constants.REST,
            constants.GOLANG,
            constants.BADGER,
            constants.EXCELIZE,
            constants.DESKTOP,
        ]
    },
    {
        name: "PyTube",
        city: "Arnhem",
        country,
        from: new Date(2024, 0),
        to: new Date(2024, 4),
        repo: 'https://gitlab.com/datails/pytube',
        stack: [
            constants.REACT,
            constants.GITLAB,
            constants.REST,
            constants.GOLANG,
            constants.BADGER,
            constants.EXCELIZE,
            constants.DESKTOP,
        ]
    },
    {
        name: "@datails/hcledit",
        city: "Arnhem",
        country,
        from: new Date(2023, 6),
        to: new Date(2023, 6),
        repo: 'https://gitlab.com/datails/node-hcl-edit',
        href: 'https://www.npmjs.com/package/@datails/hcledit',
        stack: [
            constants.GITLAB,
            constants.GOLANG,
            constants.TERRAFORM,
            constants.NODE_JS
        ]
    },
    {
        name: "Contributor of nestjs-soap",
        city: "Arnhem",
        country,
        from: new Date(2021, 7),
        to: new Date(2021, 9),
        repo: 'https://github.com/lehh/nestjs-soap',
        stack: [
            constants.NEST_JS,
            constants.JEST,
            constants.GITHUB,
        ]
    },
]