import * as constants from "../../../../common/constants";
const country = "The Netherlands"

export default [
    {
        name: "Fixx iT Professional Services B.V.",
        city: "Houten",
        country,
        title: "Junior Developer",
        stack: [
            constants.HTML,
            constants.CSS,
            constants.JS,
            constants.BOOTSTRAP,
        ],
        from: new Date(2016, 0),
        to: new Date(2016, 7),
    },
    {
        name: "IDAS Full-service Internetbureau",
        city: "Apeldoorn",
        country,
        title: "Front-end Developer",
        stack: [
            constants.HTML,
            constants.CSS,
            constants.JS,
            constants.JQUERY,
            constants.DNN,
            constants.BOOTSTRAP,
        ],
        from: new Date(2016, 7),
        to: new Date(2017, 0),
    },
    {
        name: "Entelect Europe",
        city: "Zeist",
        country,
        title: "Front-end Developer",
        stack: [
            constants.HTML,
            constants.CSS,
            constants.JS,
            constants.BITBUCKET,
            constants.JQUERY,
            constants.WEBPACK,
            constants.GULP,
            constants.REACT,
            constants.BOOTSTRAP,
        ],
        from: new Date(2017, 0),
        to: new Date(2019, 1),
    },
    {
        name: "VodafoneZiggo",
        city: "Utrecht",
        country,
        title: "Full-Stack Engineer",
        stack: [
            constants.HTML,
            constants.CSS,
            constants.JS,
            constants.TS,
            constants.REACT,
            constants.BITBUCKET,
            constants.GITLAB,
            constants.KARMA,
            constants.WEBDRIVER_IO,
            constants.JEST,
            constants.JASMINE,
            constants.DOCKER,
            constants.GCP,
            constants.WEBPACK,
            constants.ROLLUP,
            constants.NODE_JS,
            constants.YARGS,
            constants.EXPRESS,
            constants.GRAPHQL,
            constants.MONGO,
        ],
        from: new Date(2019, 1),
        to: new Date(2020, 4),
    },
    {
        name: "VodafoneZiggo",
        city: "Utrecht",
        country,
        title: "Senior Software Engineer",
        stack: [
            constants.YARGS,
            constants.NODE_JS,
            constants.AWS,
            constants.NEST_JS,
            constants.EXPRESS,
            constants.FASTIFY,
            constants.GRAPHQL,
            constants.REST,
            constants.REDIS,
            constants.DYNAMO,
            constants.MONGO,
            constants.DOCKER,
            constants.K8S,
            constants.GITLAB,
            constants.BITBUCKET,
            constants.KIBANA,
            constants.JENKINS,
            constants.SUPERTEST,
            constants.ARTILLERY,
            constants.JEST,
            constants.JASMINE,
            constants.REACT,
        ],
        from: new Date(2020, 4),
        to: new Date(2021, 11),
    },
    {
        name: "PIDZ",
        city: "Eindhoven",
        country,
        title: "Full-Stack Engineer (freelance)",
        from: new Date(2022, 0),
        to: new Date(2023, 5),
        stack: [
            constants.NODE_JS,
            constants.AWS,
            constants.DEV_OPS,
            constants.NEST_JS,
            constants.REST,
            constants.REDIS,
            constants.POSTGRES,
            constants.MICROSERVICES,
            constants.NX,
            constants.DYNAMO,
            constants.MY_SQL,
            constants.DOCKER,
            constants.K8S,
            constants.GITLAB,
            constants.BITBUCKET,
        ],
    },
    {
        name: "Beecot",
        city: "Utrecht",
        country,
        title: "Senior Software Engineer (freelance)",
        from: new Date(2022, 10),
        to: new Date(2024, 3),
        stack: [
            constants.NODE_JS,
            constants.REST,
            constants.REDIS,
            constants.DOCKER,
            constants.AWS,
            constants.TERRAFORM,
            constants.POSTGRES,
            constants.PYTHON
        ],
    },
    {
        name: "Booming.Jobs",
        city: "Amsterdam",
        country,
        title: "Cloud Engineer (freelance)",
        from: new Date(2024, 1),
        to: new Date(),
        stack: [
            constants.NODE_JS,
            constants.REST,
            constants.REDIS,
            constants.DOCKER,
            constants.GCP,
            constants.TERRAFORM,
            constants.POSTGRES,
        ],
    },
    {
        name: "Grandvision",
        city: "Amsterdam",
        country,
        title: "Senior Software Engineer (freelance)",
        from: new Date(2023, 5),
        to: new Date(),
        stack: [
            constants.NODE_JS,
            constants.REST,
            constants.REDIS,
            constants.MICROSERVICES,
            constants.GRAPHQL,
            constants.DOCKER,
            constants.K8S,
            constants.TERRAFORM,
            constants.GOLANG,
            constants.COMMERCE_TOOLS,
            constants.ALGOLIA,
            constants.AZURE,
            constants.ADYEN
        ],
    }
]