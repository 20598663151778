import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    fontStyles: {
      fontFamily: 'Megrim',
      margin: '25px 0',
      display: 'inline-flex',
      fontWeight: 500,
      fontSize: 50
    },
    root: {
      background: 'linear-gradient(to top right,#4f7afb,#39d264)',
      backgroundImage: 'linear-gradient(to top right,#5f40c3,#26dcce)',
    },
    content: {
      alignItems: 'center',
      display: 'grid',
    },
    heroButtons: {
      backgroundColor: '#4fc1e9',
    },
    minHeight: {
      position: 'relative',
      zIndex: 1,
      minHeight: '750px',
      width: '100%',
      padding: '0 25px',
      margin: 0,
      display: 'flex',
      alignContent: 'center',
      marginTop: '-80px',
      [theme.breakpoints.down("sm")] : {
        minHeight: '350px'
      }
    },
    colorWhite: {
      color: '#fff',
      fontSize: 42,
      fontWeight: 500
    },
    maxHeight: {
      position: 'absolute',
      width: '100%',
      zIndex: 0,
    },
    logo: {
      width: '50px',
      marginRight: '15px',
    },
    flex: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline'
    },
    marginNone: {
      margin: 0,
    },
    hiddenSmallMd: {
      fontSize: '1.3rem',
      lineHeight: 1.8,
      fontWeight: 300,
      [theme.breakpoints.down("sm")] : {
        display: 'none'
      }
    },
    centerMdDown: {
      [theme.breakpoints.down("sm")] : {
        textAlign: 'center',
        marginTop: '40px'
      }
    }
  })
);