import React from "react";
import { AppTextImage } from "../../../../components";
import { AppTimeline } from "../../../../framework";
import withRoot from "../../../../theme/withRoot";
import volunteerExp from "./volunteering.data";

function Text() {
  return (
    <AppTextImage
      backgroundColor="#FFF"
      title="Giving back"
      text={`
        Since 2018 I've contributed on several social-cultural initiatives.
      `}
    >
      <AppTimeline data={volunteerExp} />
    </AppTextImage>
  );
}

export default withRoot(Text)