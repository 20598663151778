import React from "react";
import {
  Typography,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@material-ui/core";
import classNames from "classnames";
import useStyles from './app-usp-bar.styles';
import withRoot from '../../theme/withRoot'

function AppCard({ desc, title, children, background }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Grid container className={classes.container} justify="space-around">
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              justify="center"
              alignContent="center"
              className={classes.titleBar}
            >
              <Avatar alt={title} className={classes.large}>
                {children}
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
              <Typography
                className={classNames(classes.title, classes.colorWhite)}
                variant="h4"
                component="h4"
              >
                {title}
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                className={classNames(classes.colorWhite)}
              >
                {desc}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </div>
      <CardMedia
        className={classes.cover}
        image={require(`../../assets/${background.toLowerCase()}`)}
      />
    </Card>
  );
}

export default withRoot(AppCard)