import {
    createStyles,
    makeStyles,
  } from "@material-ui/core/styles";

export default makeStyles((theme) =>
createStyles({
  anchor: {
    display: "flex",
    flexDirection: "column",
    fontSize: "1rem",
    lineHeight: 2,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
  },
  container: {
    ...theme.container,
    background: "#e3dff5",
  },
  content: theme.content,
  image: {
    margin: "35px 0",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
  title: {
    fontSize: "1rem !important",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    marginBottom: "1.5rem",
  },
  text: {
    fontSize: "1rem",
    lineHeight: 1.2,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    textAlign: "justify",
  },
  backgroundMain: {
    padding: "0",
    textAlign: "center",
    background: "#5e46c4",
  },
  colorLight: {
    color: "#FFF",
    fontWeight: 700,
  },
})
);