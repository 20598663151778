import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
createStyles({
  paperContainer: {
    background: "#5e46c4",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    padding: "40px 0",
    fontFamily: "'Raleway', sans-serif",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  container: theme.container,
  content: theme.content,
  icon: {
    width: "5rem",
    height: "5rem",
    color: "#FFF",
  },
  avatar: {
    width: "8rem",
    height: "8rem",
    background: 'linear-gradient(to top right,#4f7afb,#39d264)',
    backgroundImage: 'linear-gradient(to top right,#5f40c3,#26dcce)',
  },
  flex: {
    display: "flex",
  },
  contentText: {
    color: "#FFF1E3",
    fontWeight: 500,
    fontSize: '1.2rem',
    [theme.breakpoints.down("sm")]: {
      padding: "15px 0 0",
    },
  },
  fontWeight700: {
    fontWeight: 700,
  },
})
);